import React from "react";
import { Link } from "react-router-dom";

export const FeatureDetail = () => {
  return (
    <div className="uk-section uk-section-default">
      <div className="uk-container">
        <div className="uk-grid-large uk-flex-middle uk-text-center uk-text-left@l uk-grid">
          <div className=" uk-margin-medium-bottom uk-width-1-3@l  uk-first-column">
            <div className="uk-inline uk-width-xlarge">
              <h4 className="uk-h1 uk-margin-top">
                Introducing FlamingoTracker
              </h4>
              <p className="uk-text-large tm-text-large">
                FlamingoTracker is our in house custom built ACARS solution.
                Only avalible for vSilver. Start tracking your Silver flights
                with vSilver today!
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <a
                    className="uk-button uk-button-primary uk-button-large "
                    href="https://github.com/Rotate-Group/v-silver-acars-release/releases/download/v2.0.0/FlamingoTracker-Setup-2.0.0.exe"
                  >
                    Download FlamingoTracker
                  </a>
                  <br></br>
                  <small>*FSUIPC/XUIPC Required</small>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-2-3@l uk-flex-last@l">
            <img
              src="./images/acars-preview.png"
              className="box-shadow-large"
              alt="FlamingoTracker acars application"
              style={{
                borderRadius: "15px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
