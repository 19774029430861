import React from "react";

export const FeatureDetailPartnership = () => {
  return (
    <div
      className="uk-section uk-section-default uk-section-large uk-padding-remove-bottom uk-padding"
      style={{ paddingBottom: "none !important" }}
    >
      <div className="uk-container">
        <div className="uk-grid-large uk-flex-middle uk-text-center uk-text-left@l uk-grid">
          <div className="uk-width-2-3@l uk-first-column">
            <img
              src="./images/jetblue_feature.jpg"
              className="box-shadow-large"
              alt="FlamingoTracker acars application"
              style={{
                borderRadius: "15px",
              }}
            />
          </div>
          <div className=" uk-margin-medium-bottom uk-width-1-3@l   uk-flex-last@l">
            <div className="uk-inline uk-width-xlarge">
              <h4 className="uk-h1 uk-margin-top">Featuring JetBlue Routes!</h4>
              <p className="uk-text-large tm-text-large">
                Fly JetBlue routes with vBlue! We have a partnership with
                flyvblue.net to bring you the best JetBlue Flight Sim
                experience.
              </p>
              <div className="uk-grid-medium uk-flex-middle uk-flex-inline uk-grid">
                <div className="uk-first-column">
                  <a
                    className="uk-button uk-button-large"
                    href="https://crew.flyvblue.net/dashboard"
                    rel="noreferrer"
                    target="_blank"
                    style={{ backgroundColor: "#00b2e3" }}
                  >
                    Fly vBlue!
                  </a>
                  <br></br>
                  <small>*FSUIPC/XUIPC Required</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
