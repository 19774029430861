import React, { useEffect, useContext, useState } from "react";
import { Navbar } from "../components/navbar/Navbar.jsx";
import { Hero } from "../components/hero/Hero";
import { Feature } from "../components/feature/Feature";
import { FeatureDetail } from "../components/feature/FeatureDetail";
import { LivemapFull } from "../components/livemap/LivemapFull.jsx";
import { Footer } from "../components/footer/Footer.jsx";

import { refresh, isAuthenticated } from "../middleware/auth";
import { getUserInfo, getSchedulesLast } from "../middleware/core";

import { UserContext } from "../UserContext";
import LatestFlights from "../components/feature/LatestFlights";
import { FeatureDetailPartnership } from "../components/feature/FeatureDetailPartnership.tsx";
import { FeatureDetailAnnouncement } from "../components/feature/FeatureDetailAnnouncement.tsx";

export const Home = () => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [lastRouteDate, setLastRouteDate] = useState(null);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      if (isAuthenticated()) {
        const user = await refresh();
        if (user) {
          const userInfo = await getUserInfo(user.public_id);
          user.info = userInfo;
          setUserContextData(user);
          setSignedIn(true)
        }
      }

      const schedules = await getSchedulesLast();
      setLastRouteDate(schedules[0].date);
    }

    getInitialData()
  }, []);

  return (
    <>
      <div className="section-intro">
        <Navbar user={userContextData} />
        <Hero signedIn={signedIn} />
      </div>
      <Feature date={lastRouteDate} />
      <FeatureDetailAnnouncement />
      <FeatureDetailPartnership />
      <FeatureDetail />
      <LatestFlights />
      <LivemapFull />
      <Footer />
    </>
  );
};