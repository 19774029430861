import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { getLatestOrPinnedDiscordAnnouncement } from "../../middleware/core";

export const FeatureDetailAnnouncement = () => {
  const [announcement, setAnnouncement] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getInitialData = async () => {
      const announcementRequestData =
        await getLatestOrPinnedDiscordAnnouncement();
      setAnnouncement(
        announcementRequestData
          .replaceAll("\n", "<br />")
          .replaceAll("  ", "&nbsp;")
          .replaceAll(/<@\d+>/g, "")
      );
      setLoading(false);
    };

    getInitialData();
  }, []);

  return (
    <div className="uk-section  uk-section-large uk-padding uk-padding-medium-bottom">
      <div className="uk-container">
        <div
          className="uk-child-width-expand@s uk-text-left uk-grid uk-grid-match uk-grid-medium"
          uk-grid="true"
        >
          <div>
            <h4 className="uk-h1 uk-margin-top">Announcements</h4>
            {announcement ? (
              <div
                className="uk-card uk-card-default display-announcement uk-padding"
                style={{
                  whiteSpace: "pre-wrap",
                  overflowY: "scroll",
                  maxHeight: "400px",
                  fontSize: "1.2rem",
                }}
              >
                <div
                  className="uk-text-left uk-text-break uk-text-medium"
                  dangerouslySetInnerHTML={{
                    __html: announcement,
                  }}
                ></div>
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="uk-width-1-1">
                    <div className="uk-card uk-padding uk-card-default uk-card-body uk-text-left@m uk-text-center">
                      <div className="uk-grid" uk-grid="true">
                        <div className="uk-width-auto@m uk-width-1-1 uk-margin-auto-top uk-margin-auto-bottom">
                          <h3 className="uk-h1 uk-align-center">
                            No recent announcements.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="uk-width-1-1">
                    <Skeleton count={1} height={400} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
