import React, { useEffect, useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { isAuthenticated, logout } from "../../middleware/auth";

import { checkLogin } from "../../middleware/auth";

import logo from "../../images/logo-white.png";
import NavRibbon from "./NavRibbon";
import Skeleton from "react-loading-skeleton";

export const Navbar = (props) => {
  const { userContextData, setUserContextData } = useContext(UserContext);
  const [userLocation, setUserLocation] = useState("-");
  const [userCredits, setUserCredits] = useState("-");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated()) setLoading(false);
    if (!userContextData) return;
    //setUserCredits(userContextData.info.credits);
    if (userContextData.info) {
      setUserLocation(userContextData.info.last_location);
    }
    setLoading(false);
  }, [userContextData]);

  const logoutAction = () => {
    setUserContextData([]);
    location.reload();
    logout();
    location.reload();
  };

  return (
    <>
      <div className="container-wrapper nav-bg">
        <div className="uk-container uk-container">
          <nav
            className="uk-navbar-container uk-margin uk-light uk-navbar-sticky uk-navbar-transparent"
            uk-navbar="mode: click"
          >
            <div className="uk-navbar-left">
              <Link
                className="uk-navbar-item uk-logo"
                to=""
                style={{ color: "rgb(50, 66, 82)", fontWeight: 400 }}
              >
                <img
                  className="nav-logo"
                  alt="vSilver virtual airlines logo"
                  src={logo}
                ></img>
              </Link>
            </div>
            <div className="uk-navbar-right">
              <ul className="uk-navbar-nav uk-visible@m">
                <li>
                  <NavLink exact={true} to="" activeClassName="uk-active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <Link onClick={() => checkLogin()}>Crew Center</Link>
                  {props.user ? (
                    <div className="uk-navbar-dropdown">
                      <ul className="uk-nav uk-navbar-dropdown-nav uk-text-left">
                        <li>
                          <NavLink to="/book" activeClassName="uk-active">
                            Flight Schedules
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/manage" activeClassName="uk-active">
                            Manage Schedules
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/bidding" activeClassName="uk-active">
                            Bidding System
                          </NavLink>
                        </li>
                        {/* <li>
                        <Link to=""><strike>Challenges</strike></Link>
                      </li> */}
                        <li>
                          <NavLink to="/flights" activeClassName="uk-active">
                            Logbook
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/stats" activeClassName="uk-active">
                            Stats
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/roster" activeClassName="uk-active">
                            Roster
                          </NavLink>
                        </li>
                        {/* <li>
                          <a href="https://docs.FlyvSilver.net/" rel="nofollow">
                            Documentation
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
                <li>
                  <a
                    href="https://forms.office.com/r/qCnkZnHYD2"
                    target="_blank"
                  >
                    Contact
                  </a>
                </li>
                {props.user ? (
                  <li>
                    <a href="#" type="button">
                      <div
                        //src={props.user.info ? `https://flyvfrontier.b-cdn.net/${props.user.info.avatar_url}` : ''}
                        className="uk-border-circle profile-nav bg-image"
                        style={
                          props.user.info
                            ? {
                                //https://flyvfrontier.b-cdn.net/
                                background: `url('${
                                  props.user.info.avatar_url
                                    ? props.user.info.avatar_url
                                    : "https://rotate-group.b-cdn.net/user-images/default.jpg"
                                }') 0% 0% / cover`,
                              }
                            : {}
                        }
                      ></div>
                      <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-navbar-dropdown-nav uk-text-left">
                          {props.user.info ? (
                            <>
                              <li className="uk-text-small uk-nav-header">
                                👋🏽 Hello {props.user.info.username}!
                              </li>
                              <li className="uk-nav-divider"></li>
                              <li>
                                <NavLink
                                  to={`/profile/${props.user.info.username}`}
                                  activeClassName="uk-active"
                                >
                                  My Profile
                                </NavLink>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* <li><strike>Settings</strike></li> */}
                          <li className="uk-nav-divider"></li>
                          <li>
                            <a
                              className="uk-text-danger"
                              onClick={(e) => logoutAction()}
                            >
                              🏃 Log out
                            </a>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                {loading && (
                  <li>
                    <a href="#" type="button">
                      <Skeleton
                        style={{
                          borderRadius: "100%",
                          border: "2px solid #ccc",
                        }}
                        height={45}
                        width={45}
                      />
                    </a>
                  </li>
                )}
              </ul>
              <Link
                className="uk-navbar-toggle uk-hidden@m"
                uk-navbar-toggle-icon="true"
                uk-toggle="target: #offcanvas-push"
                style={{ color: "#333" }}
                to=""
              ></Link>
            </div>
          </nav>
        </div>
      </div>
      <div
        id="offcanvas-push"
        uk-offcanvas="mode: push; overlay: true; flip: true"
      >
        <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-push uk-text-left">
          <ul className="uk-nav uk-nav-default">
            <li className="uk-active">
              <Link to="">Home</Link>
            </li>
            <li className="uk-parent">
              <Link to="">Crew Center</Link>
              <ul className="uk-nav-sub">
                <li>
                  <Link to="/book">Flight Schedules</Link>
                </li>
                <li>
                  <Link to="/manage">Manage Schedules</Link>
                </li>
                <li>
                  <Link to="/pbs">PBS</Link>
                </li>
                <li>
                  <Link to="/flights">Logbook</Link>
                </li>
                <li>
                  <Link to="/stats">Stats</Link>
                </li>
                <li>
                  <Link to="/roster">Roster</Link>
                </li>
                <li>
                  <a href="https://docs.FlyvSilver.net/" rel="nofollow">
                    Documentation
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="https://forms.office.com/r/qCnkZnHYD2" target="_blank">
                Contact
              </a>
            </li>
            <li className="uk-nav-divider" />
            <li></li>
            {props.user ? (
              <>
                {props.user.info ? (
                  <>
                    <li className="uk-text-small uk-nav-header">
                      👋🏽 Hello {props.user.info.username}!
                    </li>
                    <li className="uk-nav-divider"></li>
                    <li>
                      <Link to={`/profile/${props.user.info.username}`}>
                        My Profile
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
      {props.user && !props.hideRibbon && (
        <NavRibbon
          credits={userCredits}
          location={userLocation}
          loading={loading}
        />
      )}
    </>
  );
};
